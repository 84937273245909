import React, { useEffect, useLayoutEffect, useState } from "react";
import "./../Styles/AddrerssItem.css";
import Topbar from "../Sections/Topbar";
import Navbar from "../Sections/Navbar";
import Footer from "../Sections/Footer";
import { connect } from "react-redux";
import { setCartLength, setSingleAddress } from "../Redux/actions";
import { FetchCartApi, UpdateCartApi, deleteCartApi } from "../APIS/cartApis";
import { FetchAddressApi, DeleteAddressApi, orderCreate } from "../APIS/apis";
import { FaRegHandPointRight } from "react-icons/fa";
import { SuccessToaster, ErrorToaster } from "../Toaster";
import { ShipingrateAPi } from "../APIS/ShippingApi";

import { AiOutlinePlus, AiTwotoneDelete } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { all } from "axios";
import NewAddress from "./NewAddress";
import EditAddressItem from "./EditAdressItem";
import { loadStripe } from "@stripe/stripe-js";
import Paymentmodel from "./Paymentmodel";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AddrerssItem = ({
  getSelectedLanguage,
  setCartLengthRedux,
  setSingleAddressKey,
}) => {
  const [allCartDate, setAllCartData] = useState([]);
  const [updateCartPage, setUpdateCartPage] = useState(false);
  const [address, setAddress] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [open1, setOpen1] = React.useState(false);
  const [Edit, setEdit] = useState([]);
  const [paymentType, setPaymentType] = useState(" ");
  const [DelvieryAddress, setDelvieryAddress] = useState("");
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [shippingPrice, setShippingPrice] = useState(0);
  const[shipRates,setShipRates]=useState([])

  const handleOpen1 = (item) => {
    setEdit(item);
    setOpen1(true);
  };

  useLayoutEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    FetchCartApi(Relove_Token, formdata, (callbackData) => {
      setAllCartData(callbackData);
      setCartLengthRedux(callbackData?.data?.length);
    });
  }, [updateCartPage,getSelectedLanguage]);
  const handleDeleteCart = (cartId) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      cartId: cartId,
      language: getSelectedLanguage,
    };
    deleteCartApi(Relove_Token, formdata, updateCartPage, setUpdateCartPage);
  };
  const updateCartHandle = (cartId, productId, variationId, quantity) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      cartId: cartId,
      productId: productId,
      variationId: variationId,
      quantity: quantity,
      language: getSelectedLanguage,
    };
    UpdateCartApi(Relove_Token, formdata, updateCartPage, setUpdateCartPage);
  };

  useEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    FetchAddressApi(Relove_Token, formdata, (callback) => {
      setAddress(callback?.data);
    });
  }, [getSelectedLanguage]);

  const handleRemove = (addressId) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    DeleteAddressApi(
      Relove_Token,
      addressId,
      FetchAddressApi,
      formdata,
      (callback) => {
        setAddress(callback?.data);
      }
    );
  };

  let iteam = allCartDate?.data?.map((item, i) => {

    return {
      quantity: item.quantity,
      variationId: item.variationId,
      productId: item.productDetails[0].id,
      base_price: item.variationData.base_price,
      sale_price: item.variationData.sale_price,
      sku: item.variationData.sku,
      discountPrice:item?.productDetails[0]?.discountItemAmount,
      payAmount:parseFloat(item?.variationData?.sale_price)*item?.quantity-item?.productDetails[0]?.discountItemAmount,
      shippingCost:parseFloat(shipRates[i])
    };
  });

  let payment = allCartDate?.couponInfo?.map((data, i) => {
    return {
      couponId: data.couponId,
      discountAmount: data.discountAmount,
      totalAmount: data.subtotalAmount,
      payAmount: data.grandTotal,
    };
  });

  const payItem = {
    addressId: DelvieryAddress,

    couponId: payment?.length > 0 ? payment[0]?.couponId : "",
    discountAmount: payment?.length > 0 ? payment[0]?.discountAmount : "",
    totalAmount: payment?.length > 0 ? payment[0]?.totalAmount : "",
    payAmount: payment?.length > 0 ? payment[0]?.payAmount : "",
    shippingAmount:parseFloat(shippingPrice),
    payment_method: paymentType,
    txn_id: "",
    payment_status: "",
    billing_shipping_address: DelvieryAddress,
    item: iteam,
  };

  const handleOrdercreate = () => {
    let Relove_Token = localStorage.getItem("Relove_Token");

    if (allCartDate?.data?.length !== 0 && DelvieryAddress !== "") {
      orderCreate(Relove_Token, payItem, navigation, setCartLengthRedux);
    } else if (allCartDate?.data?.length === 0) {
      ErrorToaster("Your Cart is Empty");
    } else {
      ErrorToaster("Your address is Empty");
    }

    setSingleAddressKey(DelvieryAddress);
  };

  const stripepaymentt = async () => {
    const stripe = await loadStripe(
      "pk_test_51OlnHKSDiwRAgYmRQrqPs0jNWbPj5YSQxAm1HIQWZZTFiq1TdwPwTZ1Ve0lyrNx1Vf9GbUEwGMfZzRj8X4je1LeS00OkFjrDB4"
    );
    console.log(stripe);
  };
  const [open5, setOpen5] = React.useState(false);
  const handleOpen5 = () => setOpen5(true);

  const kamlesh=()=>{
    var item = JSON.parse( localStorage.getItem('shipRates') );
    console.log("aaaaa",item)
    setShipRates(item)
    const initialValue = 0;
    const shippingRate = item.reduce(
      (accumulator, currentValue) => accumulator + parseFloat(currentValue),
      initialValue
    );
    setShippingPrice(shippingRate);
  }

  const handleshippingrate = async (id, city) => {
    let Relove_Token = localStorage.getItem("Relove_Token");

    setDelvieryAddress(id);
    var shipRate = [];
    const promises = [];
    
    for (let i = 0; i < allCartDate?.data?.length; i++) {
      const data = {
        destination: city,
        productId: allCartDate?.data[i]?.productDetails[0]?.id,
        quantity: allCartDate?.data[i]?.quantity,
      };

      const promise = new Promise((resolve, reject) => {
        ShipingrateAPi(Relove_Token, data, (callback) => {
          shipRate.push(callback?.rate);
          // console.log(shipRate, "sqwertyuio");
          localStorage.setItem("shipRates",JSON.stringify(shipRate))
          resolve();
        });
      });
      promises.push(promise);
    }

    await Promise.all(promises);
    kamlesh();
  };
  

  return (
    <>
      <Topbar />
      <Navbar />
      <div className="container my-5">
        <div className="row px-md-0 px-2">
          <div className="col-md-6 col-12">
            <div className="row">
              <div className="adreress-left">
                <h6 className="my-3">{t("CheckOutItem")}</h6>

                {allCartDate?.data?.map((item, i) => {
                  return (
                    <div className="row address-left-border-bottom">
                      <div className="col-3" id="address-left-border">
                        <div className="address-left-image">
                          <img
                            src={item?.productDetails[0]?.featured_image}
                            alt="checkout-image"
                          />
                        </div>
                      </div>
                      <div className="col-9" id="address-left-border-1">
                        <div className="d-flex address-left-side ">
                          <p className="fs-blod text-black me-2 addres-left-heading">
                            {t("Productcheckout")}
                            <span className="ms-4">:</span>
                          </p>
                          <p>{item?.productDetails[0]?.name?.slice(0, 20)}</p>
                        </div>
                        <div className="d-flex address-left-side">
                          <p className="fs-blod text-black me-2 addres-left-heading">
                            {t("Pricesmallcart")}
                            <span className="ms-5">:</span>
                          </p>
                          <p>
                            {" "}
                            {t("AED")} {item?.variationData?.sale_price}
                          </p>
                        </div>
                        <div className="d-flex address-left-side">
                          <p className="fs-blod text-black me-2 addres-left-heading">
                              {t("DiscountPrice")}
                            <span className="ms-2">:</span>
                          </p>
                          <p>
                            {" "}
                            {t("AED")} {item?.productDetails[0]?.discountItemAmount.toFixed(1)}
                          </p>
                        </div>
                      {shipRates.length>0?  <div className="d-flex address-left-side">
                          <p className="fs-blod text-black me-2 addres-left-heading">
                          {t("ShipingPrice")}
                            <span className="ms-2">:</span>
                          </p>
                          <p>
                            {" "}
                            {t("AED")} {shipRates[i]}
                          </p>
                        </div>:""}
                        <div className="d-flex address-left-side">
                          <p className="fs-blod text-black me-2 addres-left-heading">
                            {t("SubTotal")}
                            <span className="ms-3">:</span>
                          </p>
                         {shipRates.length>0? <p>
                            {t("AED")}{" "}
                            {item?.variationData?.sale_price * item?.quantity-item?.productDetails[0]?.discountItemAmount.toFixed(1)+parseFloat(shipRates[i])}
                          </p>:<p>{t("AED")}{" "}
                            {item?.variationData?.sale_price * item?.quantity-item?.productDetails[0]?.discountItemAmount.toFixed(1)}</p>}
                        </div>
                        <div className="d-flex align-items-center justify-content-between checkoutquntity mt-4">
                          <AiOutlineMinus
                            className="pointer"
                            onClick={() =>
                              updateCartHandle(
                                item.cartId,
                                item?.productDetails[0]?.id,
                                item?.variationId,
                                parseInt(item?.quantity) - 1
                              )
                            }
                          />
                          <p className="m-0">{item?.quantity}</p>
                          <AiOutlinePlus
                            className="pointer"
                            onClick={() =>
                              updateCartHandle(
                                item.cartId,
                                item?.productDetails[0]?.id,
                                item?.variationId,
                                parseInt(item?.quantity) + 1
                              )
                            }
                          />
                        </div>
                        <div className=" d-flex my-3 checkoutRemove mb-0">
                          <p>{t("Removesmallcart")}</p>
                          <p className="ms-1">
                            {" "}
                            <AiTwotoneDelete
                              onClick={() => handleDeleteCart(item.cartId)}
                            />
                          </p>
                        </div>
                      </div>
                      <div>
                        <hr className="checkouthr" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row my-3">
              <div className="checkOut-bottom-box">
                <h6>{t("CheckOut")}</h6>
                {allCartDate?.couponInfo?.map((item, i) => {
                  return (
                    <div key={i}>
                      <div className="d-flex justify-content-between checkout-iteam">
                        <p className="cheachout-heading">{t("SubTotal")}</p>
                        <p>
                          {t("AED")} {item?.subtotalAmount}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between checkout-iteam">
                        <p className="cheachout-heading">
                          {t("ShippingInfoCart")}
                        </p>
                        <p>{shippingPrice}</p>
                      </div>
                      <div className="d-flex justify-content-between checkout-iteam">
                        <p className="cheachout-heading">{t("DiscountInfo")}</p>
                        <p>{item?.discountAmount}</p>
                      </div>
                      <div className="d-flex justify-content-between checkout-iteam-total">
                        <p className="cheachout-heading">{t("TotalInfo")}</p>
                        <p>
                          {t("AED")} {item?.grandTotal + shippingPrice}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 px-md-2 px-0">
            <div className="adreress-right">
              <div className="d-flex justify-content-between align-iteam-center adreress-right-heading">
                <h6>{t("SelectDelivery")}</h6>
                <button
                  className="btn btn-dark right-button-address"
                  onClick={handleOpen}
                >
                  {t("AddNew")}
                </button>
              </div>
              <div className="row pb-md-0 pb-2">
                <div className="col-12">
                  <div className="add-right-check-box">
                    {address?.addressList?.map((item, i) => {
                      {
                        /* console.log(item) */
                      }
                      return (
                        <div className="right-address-border" key={i}>
                          <div className="form-check">
                            <input
                              class="form-check-input formcheckinput-right"
                              type="radio"
                              name="DelvieryAddress"
                              id="DelvieryAddress1"
                              onClick={() =>
                                handleshippingrate(item?.id, item?.city)
                              }
                              checked={
                                DelvieryAddress === item?.id ? true : false
                              }
                            />
                            <div className="ms-3 address-right-iteam">
                              <div>
                                <span>{item?.name}</span>
                                <span>({item?.address_type})</span>
                              </div>
                              <div>
                                <span>({item?.phone_code})</span>
                                <span>{item?.mobile}</span>
                              </div>
                              <div>
                                <span>{item?.landmark}</span>{" "}
                                <span>{item?.city}</span>{" "}
                                <span>{item?.state}</span>
                              </div>
                              <div>
                                <span>{t("PinCodeCheckout")}</span>{" "}
                                <span>{item?.code}</span>
                              </div>
                              <div className="mt-3">
                                <button
                                  className="btn btn-dark right-button-address"
                                  onClick={() => handleOpen1(item)}
                                >
                                  {t("Edit")}
                                </button>
                                <button
                                  className="btn btn-dark ms-3 right-button-address"
                                  onClick={() => handleRemove(item.id)}
                                >
                                  {t("Removesmallcart")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <NewAddress
                    open={open}
                    setOpen={setOpen}
                    setAddress={setAddress}
                    getSelectedLanguage={getSelectedLanguage}
                  />
                  <EditAddressItem
                    open1={open1}
                    setOpen1={setOpen1}
                    setAddress={setAddress}
                    getSelectedLanguage={getSelectedLanguage}
                    Edit={Edit}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="paymant-box mt-3">
                <div className="d-flex">
                  <div className="paymant-box-headding">
                    <h6>
                      {t("PaymantCheckout")} <FaRegHandPointRight />
                    </h6>
                  </div>
                  <div className="d-flex ">
                    <div className="form-check ms-4 formcheck-paytam">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        onClick={(e) => {
                          setPaymentType("cod");
                        }}
                        checked={paymentType === "cod" ? true : false}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        {t("CashDelivery")}
                      </label>
                    </div>
                    <div className="form-check ms-4 formcheck-paytam">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault3"
                        onClick={(e) => {
                          setPaymentType("stripe");
                        }}
                        checked={paymentType === "stripe" ? true : false}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        {t("StripePay")}
                      </label>
                    </div>
                  </div>
                </div>
                {paymentType === "cod" ? (
                  <div className="pay-button">
                    <button
                      className="btn btn-dark"
                      onClick={handleOrdercreate}
                    >
                      {t("BuyCheckout")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {paymentType === "stripe" ? (
                  <div className="pay-button">
                    <button className="btn btn-dark" onClick={stripepaymentt}>
                      {t("PayCheckout")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <Paymentmodel open5={open5} setOpen5={setOpen5} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCartLengthRedux: (parameter) => {
      dispatch(setCartLength(parameter));
    },
    setSingleAddressKey: (parameter) => {
      dispatch(setSingleAddress(parameter));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddrerssItem);
