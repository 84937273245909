import React, { useEffect, useState, useLayoutEffect } from "react";
import { NavLink } from "react-bootstrap";
import Topbar from "../Sections/Topbar";
import Navbar from "../Sections/Navbar";
import Footer from "../Sections/Footer";
import { getSingleProduct } from "../APIS/apis";
import SliderImage from "react-zoom-slider";
import { AiFillStar, AiOutlinePlus, AiOutlineMinus,AiOutlineHeart,AiFillHeart } from "react-icons/ai";
import Slider from "@mui/material/Slider";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { createTheme } from "@mui/material/styles";
import SimilierProducts from "./SimilierProducts";
import { ErrorToaster } from "../Toaster";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import { GoArrowRight } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


import {
  SingleProductVariationChildApi,
  SingleProductVariationApi,
  AddToCartApi,
  FetchCartApi,
} from "../APIS/cartApis";
import { AddWishlistApi,FetchWishlistApi,RemoveWishlistApi} from "../APIS/WishlistApis";
import { setCartLength,setWishLength} from "../Redux/actions";

import LoginModal from "../Modals/LoginModal";
import "./../Styles/product.css";
import AddReview from "../Pages/AddReview";
import Review from "../Pages/Review";

const theme = createTheme({
  status: {
    danger: "#fff",
  },
});

function ProductPage({getSelectedLanguage, setCartLengthRedux,setWishLengthRedux, getCartLengthRedux, getWishLengthRedux}) {
  const pathID = window.location.pathname.split("/")[2];
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [imagesData, setimagesData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [selectRootAttributes, setSelectRootAttributes] = useState(null);
  const [updateProductPage, setUpdateProductPage] = useState(false);
  const [updateProductGetCartLength, setUpdateProductGetCartLength] = useState(false);
  // const [updateProductGetWishLength, setUpdateProductGetWishtLength] = useState(false);
  const navigation = useNavigate();


  const [variationSlider, setVariationSlider] = useState([]);
  const [selectedFirstVariation, setSelectedFirstVariation] = useState(false);
  const [selectedSecondVariation, setSelectedSecondVariation] = useState(false);

  const [basePrice, setBasePrice] = useState(null);
  const [salePrice, setSalePrice] = useState(null);
  const [variationId, setvariationId] = useState(null);
const[averageRating,setAverageRating]=useState()
  const [productQuatity, setProductQantity] = useState(1);
  const [totalProductQuatity, setTotalProductQantity] = useState(null);
  const { t } = useTranslation();
const[allWishtDate,setAllWishDate]=useState([])
  useLayoutEffect(() => {

    getSingleProduct(
      pathID,
      getSelectedLanguage,
      (data) => {
        // console.log(data)
        let imageData = data.data.product[0]?.imagesGet;
        setProductData(data.data.product);
        setimagesData(imageData);
      }
    );

    window.scrollTo(0, 0);
  }, [updateProductPage, getSelectedLanguage, pathID]);

  let imagesForSlider = [];
  for (let i = 0; i < imagesData.length; i++) {
    imagesForSlider.push({ image: imagesData[i] });
  }
  const [show,setShow]=useState(false)
  const handleAddToCart = (productId, variationId, quantity) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let fromdata = {
      productId: productId,
      variationId: variationId,
      quantity: quantity,
    };
    if (!Relove_Token) {
      setShowLoginModal(true);
    } else if (!selectRootAttributes) {
      ErrorToaster("Please Select Color");
    } else if (!selectedSecondVariation) {
      ErrorToaster("Please Select Size");
    } else {
      AddToCartApi(Relove_Token, fromdata,updateProductPage, setUpdateProductPage);
      setShow(true)
    }
  };


  useEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    FetchCartApi(Relove_Token, formdata, (callbackData) => {

      setCartLengthRedux(callbackData?.data?.length)
    });
    FetchWishlistApi(Relove_Token, formdata, (callbackData) => {
      setAllWishDate(callbackData?.data)
      setWishLengthRedux(callbackData?.data?.length)

    });


  }, [updateProductGetCartLength, updateProductPage,getSelectedLanguage,setWishLengthRedux]);


  const handleWisthlist=(productId)=>{
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata={
      "language": getSelectedLanguage,
      "productId": productId
    }
    const value=allWishtDate?.filter((iteam)=>{

     return iteam.product_id===productId
    })
    // console.log(value)
    if(value[0]?.product_id===productId){
      RemoveWishlistApi(Relove_Token,formdata,updateProductPage, setUpdateProductPage,FetchWishlistApi, setWishLengthRedux)

    }
    else{
      AddWishlistApi(Relove_Token, formdata,updateProductPage, setUpdateProductPage,FetchWishlistApi, setWishLengthRedux)

    }


  }
    //  ordercreatemethod //////////

  const handlebuy=(producetId,variationId,productQuatity)=>{
    let Relove_Token = localStorage.getItem("Relove_Token");
    let fromdata = {
      productId: producetId,
      variationId: variationId,
      quantity: productQuatity,
    };
    if (!Relove_Token) {
      setShowLoginModal(true);
    } else if (!selectRootAttributes) {
      ErrorToaster("Please Select Color");
    } else if (!selectedSecondVariation) {
      ErrorToaster("Please Select Size");
    } else {
      AddToCartApi(Relove_Token, fromdata,updateProductPage, setUpdateProductPage);
      navigation("/cart")
    }
   
  }
   

  
  return (
    <div>
      <LoginModal
        setShowLoginModal={setShowLoginModal}
        showLoginModal={showLoginModal}
      />
      <Topbar />
      <Navbar />
      <div className="container products_main">
        {productData?.length === 0 ? (
          <div className="product_page_loader">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">{t('LodingProduct')}</span>
            </Spinner>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-6 product_feature_image">
              <span className="products-page-path-title">
                {t('Homeproduct')}{" > "}
                {productData[0]?.categories[0]?.categoryDetails?.name}
              </span>
              <div className="product_slider">
                 <div className="heart_icon_wishlist" onClick={()=>{
             let Relove_Token = localStorage.getItem("Relove_Token");
                     if(!Relove_Token){
                      setShowLoginModal(true)
                     }else{
                  handleWisthlist(productData[0]?.id)}}} >
                   {/* <AiOutlineHeart /> */}
                   {allWishtDate.findIndex(
                                ({ product_id }) =>  +product_id === +productData[0]?.categories[0]?.product_id
                              ) === -1 ? (
                                <AiOutlineHeart />
                              ) : (
                                <AiFillHeart color="black" 

                                // onClick={()=>handleRemoveWish(productData[0]?.id)}
                                />
                              )}
                 </div>
                {productData.length > 0 ? (
                  <SliderImage
                    className="product_slider"
                    data={
                      variationSlider.length === 0
                        ? imagesForSlider
                        : variationSlider
                    }
                    width="600px"
                    showDescription={true}
                    direction="right"
                  />
                ) : null}
              </div>

              <AddReview id={productData.length > 0? productData[0]?.id:"" }/>
            </div>
            {productData &&
              productData?.map((item,i) => {
                return (
                  <div key={i} className="col-md-6 product_right" id="product-right-id" >
                    <div className="product-right-side-information">
                      {item?.categories?.map((ele, i) => {
                        return (
                          <h6 className="product-right-side-information-polyester">
                            {ele?.categoryDetails?.name}
                          </h6>
                        );
                      })}

                      <h5 className="product-right-side-information-title">
                        {item?.name}
                      </h5>
                      <span className="product-right-side-information-title-base">
                        {item.description.slice(0,200)}
                        {"... "}
                      </span>
                      <h6 className="product-right-side-information-rating-main py-2">
                        <span className="product-right-side-information-rating">
                          3.5
                          <AiFillStar />
                        </span>{" "}
                        {t('Basedproduct')}
                      </h6>
                      <h6 className="product-right-side-information-price">
                        {t('AED')} {salePrice ? salePrice : item.sale_price}{" "}
                        <span className="product-right-side-information-discount">
                        {t('AED')}{basePrice ? basePrice : item.base_price}
                        </span>
                        <span className="product-right-side-information-off">
                          30% {t('OFF')}
                        </span>
                      </h6>
                      <span className="product-right-side-information-taxs">
                       {t('InclusiveProducet')}
                      </span>
                    </div>

                    <div className="selected-size-main py-4">
                      <div className="select-size d-flex justify-content-between">
                        <p className="fw-bold">
                          {item?.rootAttributes?.attributeName}
                        </p>
                      </div>
                      <div key={i} className="products-color">
                        <ul className="p-0 m-0 d-flex gap-3 ">
                          {item?.rootAttributes?.childData.map((rootAtt, i) => {
                            return (
                              <li
                                key={i}
                                className={
                                  selectedFirstVariation === rootAtt?.childId
                                    ? "selectRootAttributesClass"
                                    : ""
                                }
                                onClick={() => {
                                  
                                  // console.log(rootAtt.childId,selectedFirstVariation,'nnn')
                                  if(rootAtt.childId!==selectedFirstVariation)
                                  {
                                    console.log(variationSlider,"before")
                                    if(variationSlider.length>0)
                                    {
                                      setVariationSlider([])
                                    }
                                    console.log(variationSlider,"after")
                                    let userToken =
                                    localStorage.getItem("Relove_Token");
                                  if (!userToken) {
                                    setShowLoginModal(true);
                                  } else {
                                    let formdata = {
                                      productId: parseInt(item.id),
                                      childId: parseInt(rootAtt?.childId),
                                      variationId: parseInt(
                                        rootAtt?.variationId
                                      ),
                                      language:
                                        getSelectedLanguage,
                                    };
                                    SingleProductVariationChildApi(
                                      userToken,
                                      formdata,
                                      (callbackData) => {
                                        let variationImage =
                                          callbackData.variationData.images;
                                          // console.log(variationImage)
                                        setSelectedFirstVariation(
                                          rootAtt?.childId
                                        );
                                      
                                        setSelectRootAttributes(
                                          callbackData.variationData
                                            .variationInfo[0]
                                        );
                                        
                                        // setVariationSlider(variationImage)
                                       
                                          for (
                                          let i = 0;
                                          i < variationImage.length;
                                          i++
                                        ) {
                                          variationSlider.push({
                                            image: variationImage[i],
                                          });
                                        }
                                        
                                      }
                                    );
                                  }
                                  }
                                }}
                              >
                                {rootAtt?.childName}
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      {selectRootAttributes ? (
                        <div>
                          <div className="select-size d-flex justify-content-between">
                            <p className="fw-bold">
                              {" "}
                              {selectRootAttributes?.attributeName}
                            </p>
                          </div>
                          <div className="d-flex">
                          {selectRootAttributes?.childData.map((subAtt, i) => {
                            return (
                              <div key={i} className="products-sizes ">
                                <ul className="p-0 m-0 d-flex mb-3 mx-2">
                                  <li
                                    className={
                                      selectedSecondVariation ===
                                      subAtt?.childId
                                        ? "selectRootAttributesClass"
                                        : ""
                                    }
                                    onClick={() => {
                                      let userToken =
                                        localStorage.getItem("Relove_Token");
                                      let formdata = {
                                        productId: parseInt(item.id),
                                        childId: [
                                          selectedFirstVariation,
                                          subAtt?.childId,
                                        ],
                                        language:
                                          getSelectedLanguage,
                                      };
                                      setSelectedSecondVariation(
                                        subAtt?.childId
                                      );
                                      SingleProductVariationApi(
                                        userToken,
                                        formdata,
                                        (callbackData) => {
                                          setVariationSlider([]);
                                          setvariationId(
                                            callbackData?.variation[0]?.id
                                          );
                                          setTotalProductQantity(
                                            callbackData?.variation[0]?.quantity
                                          );
                                          setSalePrice(
                                            callbackData?.variation[0]
                                              ?.sale_price
                                          );
                                          setBasePrice(
                                            callbackData?.variation[0]
                                              ?.base_price
                                          );
                                          let variationImage =
                                            callbackData.variation[0].images;

                                          for (
                                            let i = 0;
                                            i < variationImage.length;
                                            i++
                                          ) {
                                            variationSlider.push({
                                              image: variationImage[i],
                                            });
                                          }
                                        }
                                      );
                                    }}
                                  >
                                    {subAtt?.childName}
                                  </li>
                                </ul>
                              </div>
                            );
                          })}
                          </div>
                        </div>
                      ) : null}

                      {selectedSecondVariation ? (
                        <div className="cart_degree">
                          <AiOutlineMinus
                            onClick={() => {
                              if (productQuatity === 1) {
                              } else {
                                setProductQantity(productQuatity - 1);
                              }
                            }}
                          />
                          <span>{productQuatity}</span>
                          <AiOutlinePlus
                            onClick={() => {
                              if (totalProductQuatity <= productQuatity) {
                              } else {
                                setProductQantity(productQuatity + 1);
                              }
                            }}
                          />

                        </div>
                        
                      ) : null}
                      {selectedSecondVariation?
                       <div className="my-3 d-flex align-items-center" style={{fontSize:"13px",color:"rgba(170, 140, 114, 1)"}}><p className="mb-0">Total Product Quatity</p><span className="ms-2">{totalProductQuatity }</span></div>:""}
                      <div className="products-buttons d-flex gap-3 my-4" style={{position:"relative"}}>
                        <button 
                          onClick={() =>
                            handleAddToCart(
                              item?.id,
                              variationId,
                              productQuatity
                            )
                          }
                        >
                          {t('AddProducet')}
                        </button>
                        
                        <button onClick={()=>handlebuy(
                          item?.id,variationId, productQuatity
                        )}>{t('BuyProducet')}</button>
                        { show? <div className="addcartlength-item-show" >
                        <div className="d-flex justify-content-center align-items-center" onClick={()=>navigation("/cart")}>
                          <p className="mb-0">{t('ViewCart')}</p>
                          <span className="me-1 ms-1">({ getCartLengthRedux})</span>
                          <span><GoArrowRight /></span>
                        </div>
                       </div>:""}
                        
                      </div>
                      
                    </div>

                    <div className="delivery-option my-4">
                      <h6>{t('DeliveryOptions')}</h6>
                      <input type="text" placeholder={t('PinCode')} />
                      <span>{t('CHANGE')}</span>
                      <ul>
                        <li>
                          <img src="/img/local_shipping.png" alt="img" />
                          <p>{t(('Getitby'))}</p>
                        </li>
                        <li>
                          <img
                            src="/img/account_balance_wallet.png"
                            alt="img"
                          />
                          <p>{t('PayonDelivery')}</p>
                        </li>
                        <li>
                          <img src="/img/currency_exchange.png" alt="img" />
                          <p>{t('EasyDays')}</p>
                        </li>
                      </ul>
                      <p>{t('OrgiginalProducts')}</p>
                    </div>

                    <div className="product-information">
                      <h6>{t('ProducetInpormation')}</h6>
                      <div className="d-flex justify-content-between align-items-center product-information-single">
                        <div className="d-flex align-items-center">
                          <img src="/img/package.png" alt="img" />
                          <div className="mx-3">
                            <p className="p-0 m-0">{t('ProductDeatials')}</p>
                            <span>{t('CareInst')}</span>
                          </div>
                        </div>
                        <MdOutlineKeyboardArrowDown />
                      </div>

                      <div className="d-flex justify-content-between align-items-center product-information-single">
                        <div className="d-flex align-items-center">
                          <img src="/img/Group 110.png" alt="img" />
                          <div className="mx-3">
                            <p className="p-0 m-0">{t('KnowYour')}</p>
                            <span>{t('DescriptionProducet')}</span>
                          </div>
                        </div>
                        <MdOutlineKeyboardArrowDown />
                      </div>

                      <div className="d-flex justify-content-between align-items-center product-information-single">
                        <div className="d-flex align-items-center">
                          <img src="/img/person.png" alt="img" />
                          <div className="mx-3">
                            <p className="p-0 m-0">{t('VendorDetails')}</p>
                            <span>{t('Manufacturedeails')}</span>
                          </div>
                        </div>
                        <MdOutlineKeyboardArrowDown />
                      </div>

                      <div className="d-flex justify-content-between align-items-center product-information-single">
                        <div className="d-flex align-items-center">
                          <img src="/img/magic_exchange.png" alt="img" />
                          <div className="mx-3">
                            <p className="p-0 m-0">{t('ReturnProduct')}</p>
                            <span>{t('KnowMoreAbout')}</span>
                          </div>
                        </div>
                        <MdOutlineKeyboardArrowDown />
                      </div>
                    </div>

                    <div className="about-product">
                      <h6>{t('AboutReloveproducet')}</h6>
                      <div className="d-flex align-items-center ">
                        <img src="/img/logo.png" alt="img" />
                        <p>
                     {t('ProucetReviewContent')}
                        </p>
                      </div>
                    </div>

                    <div className="product-rating">
                      <div className="product-rating-1">
                        <h6>{t('producetrating')}</h6>
                        <span>
                          {averageRating}<AiFillStar />
                        </span>
                        <p>{t('verifiedBuyerp')}</p>
                      </div>
                      <div className="product-rating-2">
                        <ul>
                          <li>
                            5 <AiFillStar />{" "}
                            <Slider
                              theme={theme}
                              size="small"
                              defaultValue={80}
                              aria-label="Small"
                              valueLabelDisplay="auto"
                              style={{color:"#AA8C72"}}
                            />{" "}
                            <span>300</span>
                          </li>
                          <li>
                            4 <AiFillStar />{" "}
                            <Slider
                              size="small"
                              defaultValue={70}
                              aria-label="Small"
                              valueLabelDisplay="auto"
                              style={{color:"#AA8C72"}}

                            />
                            <span>200</span>
                          </li>
                          <li>
                            3 <AiFillStar />{" "}
                            <Slider
                              size="small"
                              defaultValue={50}
                              aria-label="Small"
                              valueLabelDisplay="auto"
                              style={{color:"#AA8C72"}}

                            />
                            <span>143</span>
                          </li>
                          <li>
                            2 <AiFillStar />{" "}
                            <Slider
                              size="small"
                              defaultValue={30}
                              aria-label="Small"
                              valueLabelDisplay="auto"
                              style={{color:"#AA8C72"}}

                            />
                            <span>24</span>
                          </li>
                          <li>
                            1 <AiFillStar />{" "}
                            <Slider
                              size="small"
                              defaultValue={10}
                              aria-label="Small"
                              valueLabelDisplay="auto"
                              style={{color:"#AA8C72"}}

                            />
                            <span>45</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <Review pid={productData.length > 0? productData[0]?.id:"" } averageRating={averageRating} setAverageRating={setAverageRating}/>
                  </div>
                );
              })}
          </div>
        )}
       
      </div>
      <SimilierProducts
        updateProductPage={updateProductPage}
        setUpdateProductPage={setUpdateProductPage}
      />
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
    getCartLengthRedux: state.length.length,
    getWishLengthRedux: state.wishlength.length,
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartLengthRedux: (parameter) => {
      dispatch(setCartLength(parameter));
    },
    setWishLengthRedux: (parameter) => {
      dispatch(setWishLength(parameter));
    },
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
